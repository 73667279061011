




























































import Vue from 'vue'

export default Vue.extend({
  name: "PortfolioPageItemDetails",
  props: {
    itemData: { required: true },
    isMobile: { required: true }
  }
})
