





























































import Vue from 'vue'
import { mapGetters } from "vuex"
import PortfolioMobileDetails from "@/components/particles/Portfolio/PortfolioMobileDetails.vue"
import PortfolioPageItem from "@/views/Portfolio/PortfolioPageItem.vue"
import PortfolioPageTabs from "@/views/Portfolio/PortfolioPageTabs.vue"
import MobileAppCarousel from "@/components/particles/MobileAppCarousel/MobileAppCarousel.vue"
import PortfolioCarousel from "@/components/particles/PortfolioCarousel/PortfolioCarousel.vue";

export default Vue.extend({
  name: "PortfolioPage",
  components: {
    PortfolioCarousel,
    MobileAppCarousel,
    PortfolioPageTabs,
    PortfolioPageItem,
    PortfolioMobileDetails
  },

  data () {
    return {
      tabs: [
        { id: 'de-1', name: 'All', value: 'all'},
        { id: 'de-2', name: 'Corporate', value: 'corporate'},
        { id: 'de-3', name: 'Crypto', value: 'crypto'},
        { id: 'de-4', name: 'Dating', value: 'dating'},
        { id: 'de-5', name: 'eCommerce', value: 'ecommerce'},
        { id: 'de-6', name: 'Health', value: 'health'},
        { id: 'de-7', name: 'Real Estate', value: 'real-estate'},
        // { id: 14, name: 'Travel', value: 'travel'},
      ],
      tabsMobile: [
        { id: 'mob-1', name: 'All', value: 'all'},
        { id: 'mob-2', name: 'ChatBot', value: 'chatbot'},
        { id: 'mob-3', name: 'Crypto', value: 'crypto'},
        { id: 'mob-4', name: 'eCommerce', value: 'ecommerce'},
        { id: 'mob-5', name: 'Gambling', value: 'gambling'},
        { id: 'mob-6', name: 'Payments system', value: 'payment-system'},
        { id: 'mob-7', name: 'Travel', value: 'travel'},
      ]
    }
  },

  watch: {
    isWebVersion () {
      this.$store.dispatch('selectCategory', 'all')
    }
  },

  computed: {
    ...mapGetters({
      isWebVersion: 'portfolioWebVersion',
      portfolioItems: 'portfolioItems',
      portfolioMobileApps: 'portfolioMobileApps'
    }),
    filteredTabs: function () : Object[] {
      if (!this.isWebVersion) {
        return this.tabsMobile
      }
      return this.tabs
    },
    filteredSlider: function () {
      if (this.isWebVersion && this.portfolioItems.length > 3) {
        return {
          autoplay: true,
          autoplayTime: 3000,
          loop: true,
          display: 5,
        }
      }
      return {
        loop: false,
        display: 1,
      }
    },
    filteredMobileSlider: function () {
      if (!this.isWebVersion && this.portfolioItems.length > 3) {
        return {
          autoplay: true,
          autoplayTime: 3000,
          loop: true,
          display: 5,
        }
      }
      return {
        loop: false,
        display: 1,
      }
    },
    appKey: function () : String {
      return JSON.stringify(this.filteredSlider)
    },
    mobileAppKey: function (): String {
      return JSON.stringify(this.filteredMobileSlider)
    }
  },

})
