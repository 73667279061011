<template>
  <div class="portfolio-page-tabs">
    <v-container class="container-wrapper">
      <v-row no-gutters class="d-flex flex-nowrap">
        <v-col cols="auto">
          <div class="portfolio-page-tabs__switch">
            <v-btn
              fab
              depressed
              :color="deskBtn.color"
              class="mr-1"
              @click="webVersion = true"
            >
              <img :src="deskBtn.icon" alt="Web">
            </v-btn>
            <v-btn
              fab
              depressed
              :color="mobileBtn.color"
              @click="webVersion = false"
            >
              <img :src="mobileBtn.icon" alt="Mobile">
            </v-btn>
          </div>
        </v-col>
        <v-col
          class="d-flex align-center pl-3"
          style="max-width: calc(100% - 120px)"
        >
          <v-slide-group
            v-model="tabsModel"
            class="portfolio-page-tabs__slide"
          >
            <v-slide-item
                v-for="tab in tabs"
                :key="tab.id"
                v-slot="{ active, toggle }"
                class="portfolio-page-tabs__slide_item"
            >
              <v-btn
                text
                :input-value="active"
                active-class="portfolio-page-tabs__slide_btn--active"
                class="portfolio-page-tabs__slide_btn"
                @click="toggle"
              >
                {{ tab.name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapGetters } from "vuex"

export default {
  name: "PortfolioPageTabs",

  props: {
    tabs: {
      type: Array,
      required: true
    },
    isTabsChanged: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      tabsModel: 0,
    }
  },

  watch: {
    tabsModel (val) {
      if (val !== undefined) {
        const selectedTab = this.tabs[this.tabsModel].value
        this.$store.dispatch('selectCategory', selectedTab)
      }
    },
    isTabsChanged () {
      this.tabsModel = 0
    }
  },

  computed: {
    ...mapGetters({
      portfolioWebVersion: 'portfolioWebVersion'
    }),
    webVersion: {
      get () {
        return this.portfolioWebVersion
      },
      set (val) {
        this.$store.dispatch('switchPortfolioWebVersion', val)
      }
    },
    deskBtn: function () {
      let color = 'transparent'
      let icon = require('@/assets/icons/monitor-gray.svg')
      if (this.webVersion) {
        color = '#6363FF'
        icon = require('@/assets/icons/monitor-light.svg')
      }

      return {
        color: color,
        icon: icon
      }
    },
    mobileBtn: function () {
      let color = 'transparent'
      let icon = require('@/assets/icons/phone_iphone-gray.svg')
      if (!this.webVersion) {
        color = '#6363FF'
        icon = require('@/assets/icons/phone_iphone-light.svg')
      }

      return {
        color: color,
        icon: icon
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-page-tabs {
  height: 108px;
  display: flex;
  align-items: center;
  background: linear-gradient(88.37deg, #39CAFF -2.45%, #855CDC 94.6%);

  &__switch {
    display: inline-block;
    padding: 6px;
    background-color: #fff;
    border-radius: 39px;

    & > button {
      width: 48px !important;
      height: 48px !important;
    }
  }
  &__slide {}
  &__slide_item {
    padding: 12px;
    cursor: pointer;
  }
  &__slide_btn {
    color: #fff;
    font-weight: 400;
  }
}
</style>

<style>
.portfolio-page-tabs .v-slide-group__prev i,
.portfolio-page-tabs .v-slide-group__next i {
  color: #fff !important;
}
</style>