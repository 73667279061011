<template>
  <div
    class="portfolio-page-item"
    @click="activeDetails = !activeDetails"
    :class="{ 'portfolio-mobile': isMobile }"
  >
    <img
        :src="item.src"
        :alt="item.alt"
        class="portfolio-page-item__image"
    >
    <div
      v-if="activeDetails"
      class="portfolio-page-item__overlay"
    ></div>
    <PortfolioPageItemDetails
        v-if="activeDetails"
        :item-data="item"
        :is-mobile="isMobile"
    />
  </div>
</template>

<script>
import PortfolioPageItemDetails from "@/views/Portfolio/PortfolioPageItemDetails"

export default {
  name: "PortfolioPageItem",
  props: {
    item: { required: true },
    isMobile: { required: false, default: false }
  },
  components: { PortfolioPageItemDetails },
  data () {
    return {
      activeDetails: false
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-page-item {
  position: relative;
  height: 628px;
  cursor: pointer;

  &__image {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  &:not(.portfolio-mobile) {
    .portfolio-page-item__image {
      object-fit: cover;
      border: 1px solid #e3e3e3;
    }
    .portfolio-page-item__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(63.82deg, #000000de 0%, #54329cc2 91.34%);
    }
  }
  &.portfolio-mobile {
    .portfolio-page-item__overlay {
      position: absolute;
      top: 19px;
      left: 19px;
      right: 19px;
      bottom: 17px;
      background: linear-gradient(63.82deg, #000000de 0%, #54329cc2 91.34%);
      border-radius: 33px;
    }
  }
}
</style>