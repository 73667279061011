import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"portfolio-page-tabs"},[_c(VContainer,{staticClass:"container-wrapper"},[_c(VRow,{staticClass:"d-flex flex-nowrap",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('div',{staticClass:"portfolio-page-tabs__switch"},[_c(VBtn,{staticClass:"mr-1",attrs:{"fab":"","depressed":"","color":_vm.deskBtn.color},on:{"click":function($event){_vm.webVersion = true}}},[_c('img',{attrs:{"src":_vm.deskBtn.icon,"alt":"Web"}})]),_c(VBtn,{attrs:{"fab":"","depressed":"","color":_vm.mobileBtn.color},on:{"click":function($event){_vm.webVersion = false}}},[_c('img',{attrs:{"src":_vm.mobileBtn.icon,"alt":"Mobile"}})])],1)]),_c(VCol,{staticClass:"d-flex align-center pl-3",staticStyle:{"max-width":"calc(100% - 120px)"}},[_c(VSlideGroup,{staticClass:"portfolio-page-tabs__slide",model:{value:(_vm.tabsModel),callback:function ($$v) {_vm.tabsModel=$$v},expression:"tabsModel"}},_vm._l((_vm.tabs),function(tab){return _c(VSlideItem,{key:tab.id,staticClass:"portfolio-page-tabs__slide_item",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VBtn,{staticClass:"portfolio-page-tabs__slide_btn",attrs:{"text":"","input-value":active,"active-class":"portfolio-page-tabs__slide_btn--active"},on:{"click":toggle}},[_vm._v(" "+_vm._s(tab.name)+" ")])]}}],null,true)})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }